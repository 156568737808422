import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Document, PageTitle } from "@components"

const Nda = () => (
  <Layout>
    <Seo title="Non disclosure agreement" />
    <PageTitle title="Non disclosure agreement" />
    <Document>
      <p>Platform users (“users”) acknowledge that each organization to whose data room they may, subject to authorization by the data room owner, have access (the “Disclosing Party”), will provide them with certain information in connection with a funding round (the "Opportunity"). In consideration of the Disclosing Party disclosing Confidential Information (as defined below), users agree to the matters set out in this agreement (this "Agreement").</p>
      <h4>1. Definitions</h4>
      <p>In this Agreement the following words and expressions shall have the following meanings:</p>
      <p>"Authorised Recipient" means (a) any person directly concerned with evaluating the Opportunity to whom a user may have given the Confidential Information (or any part of it) and (b) the directors, partners and employees of the user’s professional advisers who are engaged to advise on the Opportunity and who need to be given the Confidential Information (or any part of it) in order to advise on the Opportunity.</p>
      <p>"Confidential Information" includes, without limitation, all information, data, reports, analyses, compilations, studies, interpretations, projections, forecasts, records and other materials that contains or otherwise reflects information concerning the Disclosing Party, the timing, structure and terms of any of the Opportunity that you or your Representatives may be provided by or on behalf of the Disclosing Party in the course of the evaluation of the possible Opportunity.</p>
      <p>"Representatives" means, with respect to any person, the directors, officers, employees, shareholders, advisers, agents and representatives of such person and the officers, employees and partners of any such adviser, agent or representative.</p>
      <h4>2. Confidential Information</h4>
      <ul>
        <li>2.1. All Confidential Information provided by the Disclosing Party shall be kept strictly confidential by the users and they shall take all steps necessary to preserve such confidentiality.  Users shall not disclose in whole or in part any Confidential Information to any person other than the Authorised Recipients and they shall ensure that each of those persons to whom any Confidential Information is to be disclosed is made aware of, and they shall procure that each such person or persons adheres to, the terms of this Agreement as if they were a party to it in their place.</li>
        <li>2.2. All Confidential Information that users or their Authorised Recipients receive directly or indirectly from the Disclosing Party shall remain the Disclosing Party's property.</li>
        <li>2.3. Users shall take all reasonably practicable steps to ensure that the Confidential Information is protected against theft or unauthorised access.</li>
      </ul>
      <h4>3. Use of Confidential Information</h4>
      <p>The Confidential Information shall be used by users and their Authorised Recipients solely for the purpose of evaluating the Opportunity and for no other purpose and, in particular, not in any way which is or may be detrimental to any of the Disclosing Party's interests.</p>
      <h4>4. Obligation to maintain confidentiality</h4>
      <ul>
        <li>4.1. Subject to paragraph 4.2, users’ obligation to maintain the confidentiality of the Confidential Information subsists for a period of one year from the date of access to the data room. </li>
        <li>4.2. This Agreement shall be inoperative as to those particular portions of the Confidential Information that (a) become generally available to the public other than as a result of a disclosure by the users or any of their Representatives, (b) were available to the users on a non-confidential basis, provided that the source of such information was not bound by a confidentiality agreement with or other contractual, legal or fiduciary obligation of confidentiality to The Disclosing Party or any of its affiliates with respect to such material, (c) become available to users on a non-confidential basis from a source other than the Disclosing Party or its agents or representatives provided that the source of such information was not bound by a confidentiality agreement with or other contractual, legal or fiduciary obligation of confidentiality to the Disclosing Party or any of its affiliates with respect to such material or (d) is independently developed by users or their representatives without use of the Confidential Information.</li>
      </ul>
      <h4>5. Announcements</h4>
      <ul>
        <li>5.1. Users will make no announcements relating to the Opportunity without the Disclosing Party's prior written consent.</li>
        <li>5.2. Users will not directly or indirectly discuss or comment on the existence of or any aspect of the Opportunity with any journalist or employee, freelancer or representative of any media outlet, regardless of whether they are approached by them and regardless of whether such discussions are expressed to be "on the record" or "off the record" or any similar expression.</li>
      </ul>
      <h4>6. Compulsory disclosure</h4>
      <p>If users are compelled by law or a competent regulatory authority to disclose Confidential Information, they will, so far as it is lawful, first notify the Disclosing Party and comply with its reasonable requests as to the terms of the announcement or disclosure. Users will only disclose such Confidential Information as it is advised by their legal advisers that they are obliged to disclose.</p>
      <h4>7. Return of Confidential Information</h4>
      <ul>
        <li>7.1. As soon as is practicable, and in any event within five business days, after receipt by a user of a notice from the Disclosing Party requesting it they will: (a) destroy any written Confidential Information and all copies which have been made by or on behalf of them or their Authorised Recipients; or (b) procure that any and all other Confidential Information and all copies which have been made by or on behalf of them or their Authorised Recipients shall be destroyed or expunged (if kept in an electronic storage facility) and, if requested, they will confirm to the Disclosing Party that this obligation has been complied with.</li>
        <li>7.2. To the extent that any Confidential Information is not so returned or destroyed, the Confidential Information will be held in strict confidence on the terms of this Agreement.</li>
      </ul>
      <h4>8. No representations or offer</h4>
      <ul>
        <li>8.1. Users acknowledge neither the Disclosing Party nor any of its Representatives nor any other person makes any representation or warranty (whether express or implied) as to the accuracy or completeness of the Confidential Information and that accordingly they will have no right of action (except in the case of fraud) against the Disclosing Party or any of your Representatives or any other person in relation to the accuracy, reasonableness or completeness of any of the Confidential Information.  Accordingly, neither the Disclosing Party nor any of its Representatives nor any other person shall be liable for any direct, indirect or consequential loss or damage suffered by any person as a result of relying on any statement contained in or omitted from the Confidential Information. </li>
        <li>8.2. Users also acknowledge that documents, whether containing Confidential Information or otherwise, made available to them or their Authorised Recipients prior to, in the course of, or for the purpose, looking at the Opportunity, will not constitute an offer or invitation by or on behalf of the Disclosing Party or any shareholder of the Disclosing Party, nor will those documents nor the information contained in them form the basis of, or any representation in relation to, any contract.</li>
      </ul>
      <h4>9. Inadequacy of damages</h4>
      <ul>
        <li>9.1. Without affecting any other rights or remedies that the Disclosing Party may have, users acknowledge and agree that damages alone would not be an adequate remedy for any breach by them of the terms of this Agreement and that accordingly, the Disclosing Party shall be entitled to seek the remedies of injunction, specific performance or other equitable relief (or any combination of those remedies) for any threatened or actual breach of the terms of this Agreement.</li>
        <li>9.2. No failure or delay by the Disclosing Party or any of its Representatives in exercising any right under this Agreement shall operate as a waiver thereof.</li>
      </ul>
      <h4>10. Notices</h4>
      <ul>
        <li>10.1. Any communication to be given in connection with this Agreement shall be in writing in English and shall be delivered by email and published on the user dashboard at kobofunds.com</li>
        <li>10.2. A communication sent in accordance with paragraph 10.1 shall be deemed to have been received once the notification in the dashboard appears to have been read.</li>
      </ul>
      <h4>11. Severance</h4>
      <p>If any provision of this Agreement is held to be invalid or unenforceable, that provision shall (so far as it is invalid or unenforceable) be given no effect and shall be deemed not to be included in this Agreement but without invalidating any of the remaining provisions.</p>
      <h4>12. Rights of third parties</h4>
      <p>This Agreement does not confer any rights on any person or party (other than the parties to this Agreement) pursuant to the Contracts (Rights of Third Parties) Act 1999.</p>
      <h4>13. Governing law and jurisdiction</h4>
      <p>This Agreement is governed by and is to be construed in accordance with the English law. The parties irrevocably agree that the courts of England and Wales shall have exclusive jurisdiction to settle any dispute which may arise out of or in connection with this Agreement.</p>
    </Document>
  </Layout>
)

export default Nda
